import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { createEntity as createVote } from 'app/modules/administration/vote/vote.reducer';
import { getEntities as getMrbItemList } from 'app/modules/mrb-item/mrb-item.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { MrbItemType } from 'app/shared/model/enumerations/mrb-item-type.model';
import { MrbItemStatus } from 'app/shared/model/enumerations/mrb-item-status.model';
import { IMrbItem } from 'app/shared/model/mrbItem.model';
import { IncidentVotingModal } from './incident-voting-modal';
import { CapaVotingModal } from './capa-voting-modal';
import { FollowupVotingModal } from './followup-voting-modal';

const INCIDENT_ROW_CLASS = 'table-info';
const CAPA_ROW_CLASS = 'table-warning';
const FOLLOWUP_ROW_CLASS = 'table-success';

const textTruncateStyle = {
  maxWidth: '360px',
};

const groupItemsByKey = (list: any[], key: string) => {
  return list.reduce(function (previousItem: any, currentItem: any) {
    (previousItem[currentItem[key]] = previousItem[currentItem[key]] || []).push(currentItem);
    return previousItem;
  }, {});
};

export interface IMrbItemProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const MrbItem = (props: IMrbItemProps) => {
  const { accountLogin, mrbEntity, mrbItemList, mrbItemLoading, match } = props;

  const [isMrbMember, setIsMrbMember] = useState(false);

  const [showIncidentVoteModal, setShowIncidentVoteModal] = useState(false);
  const [showCapaVoteModal, setShowCapaVoteModal] = useState(false);
  const [showFollowupVoteModal, setShowFollowupVoteModal] = useState(false);

  const [mrbItemVote, setMrbItemVote] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    props.getMrbItemList();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    props.getMrbItemList();
    props.getMrbList();
  }, []);

  useEffect(() => {
    if (mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [mrbEntity, accountLogin]);

  const voteTriggerHandler = (mrbItem: IMrbItem) => {
    setMrbItemVote(mrbItem);

    // eslint-disable-next-line default-case
    switch (mrbItem.itemType) {
      case MrbItemType.INCIDENT:
        setShowIncidentVoteModal(true);
        break;
      case MrbItemType.CAPA:
        setShowCapaVoteModal(true);
        break;
      case MrbItemType.FOLLOWUP:
        setShowFollowupVoteModal(true);
        break;
    }
  };

  const voteHandler = vote => {
    props.createVote(vote);
    setRefresh(true);
  };

  const onClickViewHandler = (entityPath: string, id) => {
    props.history.push(`${entityPath}/${id}`);
  };

  const onClickEditHandler = (entityPath: string, id) => {
    props.history.push(`${entityPath}/${id}/edit`);
  };

  const entityPathHandler = (mrbItemType: MrbItemType): string => {
    if (mrbItemType === MrbItemType.INCIDENT) {
      return '/incident';
    }
    if (mrbItemType === MrbItemType.CAPA) {
      return '/capa';
    }
    if (mrbItemType === MrbItemType.FOLLOWUP) {
      return '/followup';
    }
    return null;
  };

  const rowColorHandler = (typeType: string): string => {
    if (typeType === MrbItemType.INCIDENT) {
      return INCIDENT_ROW_CLASS;
    }
    if (typeType === MrbItemType.CAPA) {
      return CAPA_ROW_CLASS;
    }
    if (typeType === MrbItemType.FOLLOWUP) {
      return FOLLOWUP_ROW_CLASS;
    }
    return null;
  };

  const dueDateHandler = (mrbItem: IMrbItem): string => {
    if (mrbItem.itemType === MrbItemType.INCIDENT) {
      return mrbItem.incidentCreatedDate;
    }
    if (mrbItem.itemType === MrbItemType.CAPA) {
      if (mrbItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION) {
        return mrbItem.investigationDue;
      }
      if (mrbItem.status === MrbItemStatus.CAPA_IN_PLANNING) {
        return mrbItem.implementationDue;
      }
    }
    if (mrbItem.itemType === MrbItemType.FOLLOWUP) {
      return mrbItem.scheduledDate;
    }
    return null;
  };

  const assigneeHandler = (mrbItem: IMrbItem): string => {
    if (mrbItem.itemType === MrbItemType.CAPA) {
      if (mrbItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION) {
        return mrbItem.investigator?.email || null;
      }
      if (mrbItem.status === MrbItemStatus.CAPA_IN_PLANNING) {
        return mrbItem.implementor?.email || null;
      }
    }
    if (mrbItem.itemType === MrbItemType.FOLLOWUP) {
      return mrbItem.assignee?.email || null;
    }
    return null;
  };

  function sortIncidents(incidentList) {
    return incidentList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  function sortCapas(capaList) {
    return capaList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;

      if (!firstDate && secondDate) {
        return -1;
      }
      if (firstDate && !secondDate) {
        return 1;
      }

      if (!firstDate && !secondDate) {
        if (firstItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION && secondItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION) {
          if (!firstItem.investigator) {
            return -1;
          }
          if (!secondItem.investigator) {
            return 1;
          }
          return 0;
        }

        if (firstItem.status === MrbItemStatus.CAPA_IN_PLANNING && secondItem.status === MrbItemStatus.CAPA_IN_PLANNING) {
          if (!firstItem.implementor) {
            return -1;
          }
          if (!secondItem.implementor) {
            return 1;
          }
          return 0;
        }

        if (firstItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION) {
          return -1;
        }
        if (secondItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION) {
          return 1;
        }
        return 0;
      }

      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  function sortFollowups(followupList) {
    return followupList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;
      if (!firstDate) {
        return -1;
      }
      if (!secondDate) {
        return 1;
      }
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  const isAllowedToEdit = (mrbItem: IMrbItem): boolean => {
    if (isMrbMember) {
      return true;
    }
    if (mrbItem.itemType === MrbItemType.CAPA) {
      if (mrbItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION && mrbItem.investigator) {
        return accountLogin === mrbItem.investigator.login;
      }
      if (mrbItem.status === MrbItemStatus.CAPA_IN_PLANNING && mrbItem.implementor) {
        return accountLogin === mrbItem.implementor.login;
      }
    }
    if (mrbItem.itemType === MrbItemType.FOLLOWUP) {
      if (mrbItem.assignee) {
        return accountLogin === mrbItem.assignee.login;
      }
    }
    return false;
  };

  const votingButtonBuilder = (mrbItem: IMrbItem) => {
    return (
      <Button
        onClick={() => voteTriggerHandler(mrbItem)}
        disabled={!isMrbMember}
        color={!isMrbMember ? 'outline-secondary' : 'success'}
        size="sm"
        className="mr-2"
      >
        <FontAwesomeIcon icon="check-circle" />
        <span className="d-none d-md-inline">Vote</span>
      </Button>
    );
  };

  const tableBodyBuilder = () => {
    const groupedMrbItemList = groupItemsByKey([...mrbItemList], 'itemType');

    const incidentSortedList = sortIncidents(groupedMrbItemList[MrbItemType.INCIDENT] || []);
    const capaSortedList = sortCapas(groupedMrbItemList[MrbItemType.CAPA] || []);
    const followupSortedList = sortFollowups(groupedMrbItemList[MrbItemType.FOLLOWUP] || []);

    const mrbItemSortedList = [...incidentSortedList, ...capaSortedList, ...followupSortedList];

    return (
      <tbody>
        {mrbItemSortedList.map((mrbItem, i) => (
          <tr key={`entity-${i}`} className={rowColorHandler(mrbItem.itemType)}>
            <td>{mrbItem.id}</td>
            <td>{mrbItem.itemType}</td>
            <td className="text-truncate" style={textTruncateStyle}>
              {mrbItem.incidentTitle}
            </td>
            <td>{mrbItem.status}</td>
            <td>{assigneeHandler(mrbItem)}</td>
            <td>
              {dueDateHandler(mrbItem) ? <TextFormat type="date" value={dueDateHandler(mrbItem)} format={APP_LOCAL_DATE_FORMAT} /> : null}
            </td>
            <td>
              <div className="col-md-12 text-right">
                {votingButtonBuilder(mrbItem)}

                <Button
                  onClick={() => onClickViewHandler(entityPathHandler(mrbItem.itemType), mrbItem.id)}
                  color="info"
                  size="sm"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                </Button>
                <Button
                  onClick={() => onClickEditHandler(entityPathHandler(mrbItem.itemType), mrbItem.id)}
                  disabled={!isAllowedToEdit(mrbItem)}
                  color={!isAllowedToEdit(mrbItem) ? 'outline-secondary' : 'primary'}
                  size="sm"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div>
      <h2 id="mrb-item-heading">MRB Items</h2>
      <div className="table-responsive">
        {mrbItemList && mrbItemList.length > 0 ? (
          <Table className="table-hover" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Incident Title</th>
                <th>Status</th>
                <th>Assignee</th>
                <th>Due Date</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            {tableBodyBuilder()}
          </Table>
        ) : (
          !mrbItemLoading && <div className="alert alert-warning">No MRB Items found</div>
        )}
      </div>
      {showIncidentVoteModal ? (
        <IncidentVotingModal
          showModal={showIncidentVoteModal}
          toggleModal={() => setShowIncidentVoteModal(false)}
          incident={mrbItemVote}
          voteHandler={vote => voteHandler(vote)}
          rowClassName={INCIDENT_ROW_CLASS}
        />
      ) : null}
      {showCapaVoteModal ? (
        <CapaVotingModal
          showModal={showCapaVoteModal}
          toggleModal={() => setShowCapaVoteModal(false)}
          capa={mrbItemVote}
          voteHandler={vote => voteHandler(vote)}
          rowClassName={CAPA_ROW_CLASS}
        />
      ) : null}
      {showFollowupVoteModal ? (
        <FollowupVotingModal
          showModal={showFollowupVoteModal}
          toggleModal={() => setShowFollowupVoteModal(false)}
          followup={mrbItemVote}
          voteHandler={vote => voteHandler(vote)}
          rowClassName={FOLLOWUP_ROW_CLASS}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ authentication, managementReviewBoard, mrbItem }: IRootState) => ({
  accountLogin: authentication.account.login,
  mrbEntity: managementReviewBoard.entity,
  mrbItemList: mrbItem.entities,
  mrbItemLoading: mrbItem.loading,
});

const mapDispatchToProps = {
  getMrbList,
  getMrbEntity,
  createVote,
  getMrbItemList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MrbItem);
