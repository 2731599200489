import { ICapa } from 'app/shared/model/capa.model';
import { IIncident } from 'app/shared/model/incident.model';
import { IFollowup } from 'app/shared/model/followup.model';
import { IUser } from 'app/shared/model/user.model';
import { VoteOutcome } from 'app/shared/model/enumerations/vote-outcome.model';

export interface IVote {
  id?: number;
  note?: string;
  outcome?: VoteOutcome;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  capa?: ICapa;
  incident?: IIncident;
  followup?: IFollowup;
  user?: IUser;
  incidentTitle?: string;
}

export const defaultValue: Readonly<IVote> = {};
