import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';

import { getSession } from './shared/reducers/authentication';
import { getProfile } from './shared/reducers/application-profile';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { getEntities as getMrbItemList } from 'app/modules/mrb-item/mrb-item.reducer';
import { getEntities as getAllRecordList, reset as resetAllRecords } from 'app/modules/all-record/all-record.reducer';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { MrbItemType } from './shared/model/enumerations/mrb-item-type.model';
import { MrbItemStatus } from './shared/model/enumerations/mrb-item-status.model';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const { isAuthenticated, isAdmin, ribbonEnv, isInProduction, isSwaggerEnabled, accountLogin, mrbList, mrbEntity, mrbItemList } = props;
  const [isMrbMember, setIsMrbMember] = useState(false);
  const [isMrbItemUser, setIsMrbItemUser] = useState(false);

  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      props.getMrbItemList();
      props.getMrbList();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && mrbList.length === 1) {
      props.getMrbEntity(mrbList[0]?.id);
    }
  }, [isAuthenticated, mrbList]);

  useEffect(() => {
    if (isAuthenticated && mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [isAuthenticated, mrbEntity, accountLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      let mrbItemUserLogins = [];
      mrbItemList.forEach(mrbItem => {
        if (mrbItem.itemType === MrbItemType.CAPA) {
          if (mrbItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION && mrbItem.investigator) {
            mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.investigator.login];
          }
          if (mrbItem.status === MrbItemStatus.CAPA_IN_PLANNING && mrbItem.implementor) {
            mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.implementor.login];
          }
        }
        if (mrbItem.itemType === MrbItemType.FOLLOWUP) {
          if (mrbItem.assignee) {
            mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.assignee.login];
          }
        }
      });
      mrbItemUserLogins = [...new Set(mrbItemUserLogins)];
      setIsMrbItemUser(mrbItemUserLogins.some(login => login === accountLogin));
    }
  }, [isAuthenticated, mrbItemList, accountLogin]);

  const paddingTop = '60px';
  return (
    <Router basename={baseHref}>
      <div className="app-container" style={{ paddingTop }}>
        <ToastContainer
          position={toast.POSITION.TOP_LEFT}
          style={{ width: '450px' }}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isSwaggerEnabled={isSwaggerEnabled}
            isMrbMember={isMrbMember}
            isMrbItemUser={isMrbItemUser}
            accountLogin={accountLogin}
          />
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
          <Card className="jh-card">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Card>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile, managementReviewBoard, allRecord, mrbItem }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  accountLogin: authentication.account.login,

  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,

  mrbList: managementReviewBoard.entities,
  mrbEntity: managementReviewBoard.entity,

  mrbItemList: mrbItem.entities,
  allRecordList: allRecord.entities,
});

const mapDispatchToProps = {
  getSession,
  getProfile,
  getMrbList,
  getMrbEntity,
  getMrbItemList,
  getAllRecordList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
