import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getFollowups } from 'app/entities/followup/followup.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getManagementReviewBoards } from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { getEntities as getIncidents } from 'app/entities/incident/incident.reducer';
import { createEntity, getEntity, reset, updateEntity } from './capa.reducer';
import { convertDateFromServer, displayDefaultDate } from 'app/shared/util/date-utils';
import BackButton from 'app/common/components/ui/button/BackButton';
import capa from "app/entities/capa/capa";
import { FieldMessage } from '../../common/components/ui/button/FieldMessage'
import { CheckboxIcon } from '../../common/components/ui/button/CheckboxIcon'

export interface ICapaUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const CapaUpdate = (props: ICapaUpdateProps) => {
  const { capaEntity, followups, users, managementReviewBoards, incidents, loading, updating } = props;

  const BEFORE_VOTING_MSG = 'Field required before voting can commence';

  const [followupId, setFollowupId] = useState('0');
  const [mrbId, setMrbId] = useState('0');
  const [incidentId, setIncidentId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);


  const [rootCause, setRootCause] = useState(null);
  const [remedialAction, setRemedialAction] = useState(null);
  const [correctiveAction, setCorrectiveAction] = useState(null);
  const [preventativeAction, setPreventativeAction] = useState(null);
  const [implementationPlan, setImplementationPlan] = useState(null);
  const [investigationDue, setInvestigationDue] = useState(null);
  const [implementationDue, setImplementationDue] = useState(null);
  const [investigatorId, setInvestigatorId] = useState(null);
  const [implementorId, setImplementorId] = useState(null);

  const handleClose = () => {
    props.history.goBack();
  };

  useEffect(() => {
    setRootCause(capaEntity.rootCause)
    setRemedialAction(capaEntity.remedialAction)
    setCorrectiveAction(capaEntity.correctiveAction)
    setPreventativeAction(capaEntity.preventativeAction)
    setImplementationPlan(capaEntity.implementationPlan)
    setInvestigationDue(capaEntity.investigationDue)
    setImplementationDue(capaEntity.implementationDue)
    setInvestigatorId(capaEntity.investigator?.id)
    setImplementorId(capaEntity.implementor?.id)
  }, [capaEntity]);

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }

    props.getFollowups();
    props.getUsers();
    props.getManagementReviewBoards();
    props.getIncidents();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.followup = {};
    values.followup.id = capaEntity.followupId;

    if (errors.length === 0) {
      const entity = {
        ...capaEntity,
        ...values,
      };
      entity.user = values.user;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="egcapaApp.capa.home.createOrEditLabel">Edit a CAPA</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <span>
              {(capaEntity.status === 'IN_INVESTIGATION') ? (
                <span>
                  The following fields are required before voting can commence:<br />
                  <ul className="icon-checklist">
                    <li><CheckboxIcon isChecked={!!capaEntity.rootCause} />&nbsp;Root Cause</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.remedialAction} />&nbsp;Remedial Action</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.correctiveAction} />&nbsp;Corrective Action</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.preventativeAction} />&nbsp;Preventative Action</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.investigator?.id} />&nbsp;Investigator</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.investigationDue} />&nbsp;Investigation Due Date</li>
                  </ul>
                </span>
              ) : ((capaEntity.status === 'IN_PLANNING') ? (
                <span>
                  The following fields are required before voting can commence:<br />
                  <ul>
                    <li><CheckboxIcon isChecked={!!capaEntity.implementor?.id} />&nbsp;Implementor</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.implementationDue} />&nbsp;Implementation Due Date</li>
                    <li><CheckboxIcon isChecked={!!capaEntity.implementationPlan} />&nbsp;Implementation Plan</li>
                  </ul>
                </span>
              ) : null)}

              <AvForm model={isNew ? {} : capaEntity} onSubmit={saveEntity}>
                <input type="hidden" name="status" value={capaEntity.status}></input>
                {!isNew ? (
                  <AvGroup>
                    <Label for="capa-id">ID</Label>
                    <AvInput id="capa-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="investigationInstructionsLabel" for="capa-investigationInstructions">
                    Investigation Instructions
                  </Label>
                  <AvField
                    id="capa-investigationInstructions"
                    type="textarea"
                    name="investigationInstructions"
                    validate={{
                      maxLength: { value: 2000 }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="rootCauseLabel" for="capa-rootCause">
                    Root Cause
                  </Label>
                  <AvField
                    id="capa-rootCause"
                    type="textarea"
                    name="rootCause"
                    value={rootCause}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setRootCause(e.target.value) }}
                    validate={{
                      maxLength: { value: 2000 }
                    }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !rootCause}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="remedialActionLabel" for="capa-remedialAction">
                    Remedial Action
                  </Label>
                  <AvField
                    id="capa-remedialAction"
                    type="textarea"
                    name="remedialAction"
                    value={remedialAction}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setRemedialAction(e.target.value) }}
                    validate={{
                      maxLength: { value: 2000 }
                    }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !remedialAction}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="correctiveActionLabel" for="capa-correctiveAction">
                    Corrective Action
                  </Label>
                  <AvField
                    id="capa-correctiveAction"
                    type="textarea"
                    name="correctiveAction"
                    value={correctiveAction}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCorrectiveAction(e.target.value) }}
                    validate={{
                      maxLength: { value: 2000 }
                    }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !correctiveAction}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="preventativeActionLabel" for="capa-preventativeAction">
                    Preventative Action
                  </Label>
                  <AvField
                    id="capa-preventativeAction"
                    type="textarea"
                    name="preventativeAction"
                    value={preventativeAction}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setPreventativeAction(e.target.value) }}
                    validate={{
                      maxLength: { value: 2000 }
                    }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !preventativeAction}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="implementationPlanLabel" for="capa-implementationPlan">
                    Implementation Plan
                  </Label>
                  <AvField
                    id="capa-implementationPlan"
                    type="textarea"
                    name="implementationPlan"
                    value={implementationPlan}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setImplementationPlan(e.target.value) }}
                    validate={{ maxLength: { value: 2000 } }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_PLANNING' && !implementationPlan}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="investigationDueLabel" for="capa-investigationDue">
                    Investigation Due
                  </Label>
                  <AvInput
                    id="capa-investigationDue"
                    type="date"
                    className="form-control"
                    name="investigationDue"
                    placeholder={'MM/DD/YYYY'}
                    value={isNew ? displayDefaultDate() : convertDateFromServer(props.capaEntity.investigationDue)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInvestigationDue(e.target.value) }}
                    validate={{ date: { format: 'MM/DD/YYYY' } }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !investigationDue}
                    message={BEFORE_VOTING_MSG}
                  />

                </AvGroup>
                <AvGroup>
                  <Label id="implementationDueLabel" for="capa-implementationDue">
                    Implementation Due
                  </Label>
                  <AvInput
                    id="capa-implementationDue"
                    type="date"
                    className="form-control"
                    name="implementationDue"
                    placeholder={'MM/DD/YYYY'}
                    value={isNew ? displayDefaultDate() : convertDateFromServer(props.capaEntity.implementationDue)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setImplementationDue(e.target.value) }}
                    validate={{ date: { format: 'MM/DD/YYYY' } }}
                  />
                  <FieldMessage
                    condition={capaEntity.status === 'IN_PLANNING' && !implementationDue}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="capa-status">
                    Status
                  </Label>
                  <AvInput
                    id="capa-status"
                    type="select"
                    className="form-control"
                    name="status"
                    disabled
                    value={(!isNew && capaEntity.status) || 'IN_INVESTIGATION'}
                  >
                    <option value="IN_INVESTIGATION">IN_INVESTIGATION</option>
                    <option value="IN_PLANNING">IN_PLANNING</option>
                    <option value="FOLLOWUP">FOLLOWUP</option>
                  </AvInput>
                </AvGroup>
                {capaEntity.incident == null ? '' : (
                  <AvGroup>
                    <Label for="incident-capa">Incident</Label>
                    <div>
                      <a href={"/incident/" + capaEntity.incident.id}>{capaEntity.incident.incidentId} - {capaEntity.incident.title}</a>
                    </div>
                  </AvGroup>
                )}
                {capaEntity.followupId == null ? '' : (
                  <AvGroup>
                    <Label for="followup-capa">Followup</Label>
                    <div>
                      <a href={"/followup/" + capaEntity.followupId}>{capaEntity.followupId}</a>
                    </div>
                  </AvGroup>
                )}
                <AvGroup>
                  <Label for="capa-investigator">Investigator</Label>
                  <AvInput id="capa-investigator"
                    type="select"
                    className="form-control"
                    name="investigator.id"
                    value={investigatorId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInvestigatorId(e.target.value) }}
                  >
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.email}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <FieldMessage
                    condition={capaEntity.status === 'IN_INVESTIGATION' && !investigatorId}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="capa-implementor">Implementor</Label>
                  <AvInput id="capa-implementor"
                    type="select"
                    className="form-control"
                    name="implementor.id"
                    value={implementorId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setImplementorId(e.target.value) }}
                  >
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.email}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <FieldMessage
                    condition={capaEntity.status === 'IN_PLANNING' && !implementorId}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <BackButton id="cancel-save" replace={true} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </BackButton>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  followups: storeState.followup.entities,
  users: storeState.userManagement.users,
  managementReviewBoards: storeState.managementReviewBoard.entities,
  incidents: storeState.incident.entities,
  capaEntity: storeState.capa.entity,
  loading: storeState.capa.loading,
  updating: storeState.capa.updating,
  updateSuccess: storeState.capa.updateSuccess,
});

const mapDispatchToProps = {
  getFollowups,
  getUsers,
  getManagementReviewBoards,
  getIncidents,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CapaUpdate);
