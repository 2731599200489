import axios from 'axios';
import {
  ICrudDeleteAction,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  loadMoreDataWhenScrolled,
  parseHeaderForLinks,
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, ICapa } from 'app/shared/model/capa.model';

export const ACTION_TYPES = {
  FETCH_CAPA_LIST: 'capa/FETCH_CAPA_LIST',
  FETCH_CAPA: 'capa/FETCH_CAPA',
  CREATE_CAPA: 'capa/CREATE_CAPA',
  UPDATE_CAPA: 'capa/UPDATE_CAPA',
  DELETE_CAPA: 'capa/DELETE_CAPA',
  RESET: 'capa/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICapa>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type CapaState = Readonly<typeof initialState>;

// Reducer

export default (state: CapaState = initialState, action): CapaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CAPA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CAPA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CAPA):
    case REQUEST(ACTION_TYPES.UPDATE_CAPA):
    case REQUEST(ACTION_TYPES.DELETE_CAPA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CAPA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CAPA):
    case FAILURE(ACTION_TYPES.CREATE_CAPA):
    case FAILURE(ACTION_TYPES.UPDATE_CAPA):
    case FAILURE(ACTION_TYPES.DELETE_CAPA):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CAPA_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_CAPA):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CAPA):
    case SUCCESS(ACTION_TYPES.UPDATE_CAPA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CAPA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/capas';

// Actions

export const getEntities: ICrudGetAllAction<ICapa> = (page, size, sort) => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  // JHipster shared code sorts by surrogate key by default and it's not displayed on the Indcient
  // list, so we know this is the initial page load if we see it as a sort param.
  // JHipster doesn't support multiple levels of sorting out of the box with React.  Spring does tho...
  if (page === 0 && sort === 'id,asc') {
    requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=status,desc` : ''}`;
  }

  return {
    type: ACTION_TYPES.FETCH_CAPA_LIST,
    payload: axios.get<ICapa>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ICapa> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CAPA,
    payload: axios.get<ICapa>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICapa> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CAPA,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<ICapa> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CAPA,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICapa> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CAPA,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
