import { ICapa } from 'app/shared/model/capa.model';
import { IManagementReviewBoard } from 'app/shared/model/management-review-board.model';
import { IncidentStatus } from 'app/shared/model/enumerations/incident-status.model';

export interface IIncident {
  id?: number;
  title?: string;
  description?: string;
  actionTaken?: string;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  mrbComment?: string;
  status?: IncidentStatus;
  incidentId?: string;
  parent?: IIncident;
  capa?: ICapa;
  mrb?: IManagementReviewBoard;
  capaId?: number;
  followupId?: number;
}

export const defaultValue: Readonly<IIncident> = {};
