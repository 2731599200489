import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';

import {NavDropdown} from './menu-components';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="wrench" to="/account/settings">
      Settings
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      Password
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      Sign out
    </MenuItem>
  </>
);

const accountMenuItems = (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login">
      Sign in
    </MenuItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, accountLogin = null }) => (
  <NavDropdown icon="user" name={accountLogin ? `Hi, ${accountLogin}` : "Account"} id="account-menu">
    {isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}
  </NavDropdown>
);

export default AccountMenu;
