import React from 'react';

import { NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/eg-header.svg" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIcon /> <span className="brand-title">E-g CAPA</span>
    <span className="navbar-version">{appConfig.VERSION}</span>
  </NavbarBrand>
);

export const MrbItems = props => (
  <NavItem>
    <NavLink tag={Link} to="/mrb-items" className="d-flex align-items-center">
      <FontAwesomeIcon icon="fire" />
      <span>MRB Items</span>
    </NavLink>
  </NavItem>
);

export const AllRecords = props => (
  <NavItem>
    <NavLink tag={Link} to="/all-records" className="d-flex align-items-center">
      <FontAwesomeIcon icon="briefcase" />
      <span>All Records</span>
    </NavLink>
  </NavItem>
);
