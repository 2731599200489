import { IFollowup } from 'app/shared/model/followup.model';
import { IUser } from 'app/shared/model/user.model';
import { IManagementReviewBoard } from 'app/shared/model/management-review-board.model';
import { IIncident } from 'app/shared/model/incident.model';
import { CapaStatus } from 'app/shared/model/enumerations/capa-status.model';

export interface ICapa {
  id?: number;
  investigationInstructions?: string;
  rootCause?: string;
  remedialAction?: string;
  correctiveAction?: string;
  preventativeAction?: string;
  implementationPlan?: string;
  investigationDue?: string;
  implementationDue?: string;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  status?: CapaStatus;
  followup?: IFollowup;
  investigator?: IUser;
  implementor?: IUser;
  mrb?: IManagementReviewBoard;
  incident?: IIncident;
  followupId?: number;
}

export const defaultValue: Readonly<ICapa> = {};
