import React from 'react';
import {Switch} from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Capa from './capa';
import CapaDetail from './capa-detail';
import CapaUpdate from './capa-update';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CapaUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CapaDetail} />
      <ErrorBoundaryRoute path={match.url} component={Capa} />
    </Switch>
  </>
);

export default Routes;
