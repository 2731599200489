import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getManagementReviewBoards } from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getCapas } from 'app/entities/capa/capa.reducer';
import { createEntity, getEntity, reset, updateEntity } from './followup.reducer';
import { convertDateFromServer, displayDefaultDate } from 'app/shared/util/date-utils';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { TextFormat } from 'react-jhipster';
import BackButton from 'app/common/components/ui/button/BackButton';
import { FieldMessage } from '../../common/components/ui/button/FieldMessage'
import { CheckboxIcon } from '../../common/components/ui/button/CheckboxIcon'

export interface IFollowupUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const FollowupUpdate = (props: IFollowupUpdateProps) => {
  const { followupEntity, users, loading, updating } = props;
  const BEFORE_VOTING_MSG = 'Field required before voting can commence';

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const [assigneeId, setAssigneeId] = useState(null);
  const [note, setNote] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);

  const handleClose = () => {
    props.history.goBack();
  };

  useEffect(() => {
    setAssigneeId(followupEntity.assignee?.id)
    setNote(followupEntity.note)
    setScheduledDate(followupEntity.scheduledDate)
  }, [followupEntity]);

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }

    props.getManagementReviewBoards();
    props.getUsers();
    props.getCapas();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {

    if (errors.length === 0) {
      const entity = {
        ...followupEntity,
        ...values,
      };
      entity.user = values.user;

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="egcapaApp.followup.home.createOrEditLabel">Edit a Followup</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (

            <span>

              {((followupEntity.status === 'SCHEDULED') ? (
                <span>
                  The following fields are required before voting can commence:<br />
                  <ul className="icon-checklist">
                    <li><CheckboxIcon isChecked={!!followupEntity.assignee?.id} />&nbsp;Assignee</li>
                    <li><CheckboxIcon isChecked={!!followupEntity.note} />&nbsp;Notes</li>
                    <li><CheckboxIcon isChecked={!!followupEntity.scheduledDate} />&nbsp;Due Date</li>
                  </ul>
                </span>
              ) : null)}

              <AvForm model={isNew ? {} : followupEntity} onSubmit={saveEntity}>
                <input type="hidden" name="status" value={followupEntity.status} />
                {!isNew ? (
                  <AvGroup>
                    <Label for="followup-id">ID</Label>
                    <AvInput id="followup-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="noteLabel" for="followup-note">
                    Notes
                  </Label>
                  <AvField
                    id="followup-note"
                    type="textarea"
                    name="note"
                    value={note}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNote(e.target.value) }}
                    validate={{ maxLength: { value: 2000 } }}
                  />
                  <FieldMessage
                    condition={followupEntity.status === 'SCHEDULED' && !note}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="scheduledDateLabel" for="followup-scheduledDate">
                    Due Date
                  </Label>
                  <AvInput
                    id="followup-scheduledDate"
                    type="date"
                    className="form-control"
                    name="scheduledDate"
                    placeholder={'MM/DD/YYYY'}
                    value={isNew ? displayDefaultDate() : convertDateFromServer(props.followupEntity.scheduledDate)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setScheduledDate(e.target.value) }}
                    validate={{ date: { format: 'MM/DD/YYYY' } }}
                  />
                  <FieldMessage
                    condition={followupEntity.status === 'SCHEDULED' && !scheduledDate}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="followup-id">Created Date</Label>
                  <div>
                    <TextFormat value={followupEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
                  </div>
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="followup-status">
                    Status
                  </Label>
                  <AvInput
                    id="followup-status"
                    type="select"
                    className="form-control"
                    name="status"
                    disabled
                    value={(!isNew && followupEntity.status) || 'REJECTED'}
                  >
                    <option value="REJECTED">REJECTED</option>
                    <option value="SCHEDULED">SCHEDULED</option>
                    <option value="CAPA_EFFECTIVE">CAPA_EFFECTIVE</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="followup-assignee">Assignee</Label>
                  <AvInput id="followup-assignee"
                    type="select"
                    className="form-control"
                    name="assignee.id"
                    value={assigneeId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setAssigneeId(e.target.value) }}
                  >
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.email}
                        </option>
                      ))
                      : null}
                  </AvInput>
                  <FieldMessage
                    condition={followupEntity.status === 'SCHEDULED' && !assigneeId}
                    message={BEFORE_VOTING_MSG}
                  />
                </AvGroup>
                {followupEntity.capa == null ? '' : (
                  <span>
                    <AvGroup>
                      <Label for="followup-capa">CAPA</Label>
                      <div>
                        <a href={"/capa/" + followupEntity.capa.id}>{followupEntity.capa.id}</a>
                      </div>
                    </AvGroup>
                    <AvGroup>
                      <Label for="followup-incident">Incident</Label>
                      <div>
                        <a href={"/incident/" + followupEntity.capa.incident.id}>{followupEntity.capa.incident.incidentId} - {followupEntity.capa.incident.title}</a>
                      </div>
                    </AvGroup>
                  </span>
                )}
                <BackButton id="cancel-save" replace={true} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </BackButton>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  managementReviewBoards: storeState.managementReviewBoard.entities,
  users: storeState.userManagement.users,
  capas: storeState.capa.entities,
  followupEntity: storeState.followup.entity,
  loading: storeState.followup.loading,
  updating: storeState.followup.updating,
  updateSuccess: storeState.followup.updateSuccess,
});

const mapDispatchToProps = {
  getManagementReviewBoards,
  getUsers,
  getCapas,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FollowupUpdate);
