import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {getSortState, TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities, reset} from './capa.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';

export interface ICapaProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Capa = (props: ICapaProps) => {
  const { capaList, match, loading } = props;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [sorting, setSorting] = useState(false);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const resetAll = () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
    setSorting(true);
  };

  const onClickViewHandler = (id) => {
    props.history.push(`${match.url}/${id}`);
  }

  const onClickEditHandler = (id) => {
    props.history.push(`${match.url}/${id}/edit`);
  }

  const onClickLinkTitleHandler = (id) => {
    props.history.push(`/incident/${id}`);
  }

  const onClickLinkIdHandler = (id) => {
    props.history.push(`${match.url}/${id}`);
  }

  return (
    <div>
      <h2 id="capa-heading">
        CAPA
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          pageStart={paginationState.activePage}
          loadMore={handleLoadMore}
          hasMore={paginationState.activePage - 1 < props.links.next}
          loader={<div className="loader">Loading ...</div>}
          threshold={0}
          initialLoad={false}
        >
          {capaList && capaList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    ID <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Title of Incident
                  </th>
                  <th className="hand" onClick={sort('status')}>
                    Status <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Assignee
                  </th>
                  <th>
                    Due
                  </th>
                </tr>
              </thead>
              <tbody>
                {capaList.map((capa, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button onClick={() => onClickLinkIdHandler(capa.id)} color="link" size="sm">
                        {capa.id}
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => onClickLinkTitleHandler(capa.incident?.id)} color="link" size="sm">
                        {capa.incident?.incidentId || null} - {capa.incident?.title || null}
                      </Button>
                    </td>
                    <td>{capa.status}</td>
                    <td>{capa.investigator && capa.implementor ? capa.implementor.email : capa.investigator ? capa.investigator.email : ''}</td>
                    <td>{capa.investigationDue && capa.implementationDue ? <TextFormat type="date" value={capa.implementationDue} format={APP_LOCAL_DATE_FORMAT} /> : capa.investigationDue ? <TextFormat type="date" value={capa.investigationDue} format={APP_LOCAL_DATE_FORMAT} /> : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button onClick={() => onClickViewHandler(capa.id)} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button onClick={() => onClickEditHandler(capa.id)} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No CAPAs found</div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ capa }: IRootState) => ({
  capaList: capa.entities,
  loading: capa.loading,
  totalItems: capa.totalItems,
  links: capa.links,
  entity: capa.entity,
  updateSuccess: capa.updateSuccess,
});

const mapDispatchToProps = {
  getEntities,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Capa);
