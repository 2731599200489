import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './capa.reducer';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import BackButton from 'app/common/components/ui/button/BackButton';
import { CapaStatus } from 'app/shared/model/enumerations/capa-status.model';

export interface ICapaDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CapaDetail = (props: ICapaDetailProps) => {
  const { accountLogin, capaEntity, mrbList, mrbEntity } = props;

  const [isMrbMember, setIsMrbMember] = useState(false);

  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getMrbList();
  }, []);

  useEffect(() => {
    if (mrbList.length === 1) {
      props.getMrbEntity(mrbList[0]?.id);
    }
  }, [mrbList]);

  useEffect(() => {
    if (mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [mrbEntity, accountLogin]);

  const isAllowedToEdit = (): boolean => {
    const status = capaEntity.status;
    const investigatorLogin = capaEntity.investigator?.login || null;
    const implementorLogin = capaEntity.implementor?.login || null;

    if (
      isMrbMember ||
      (status === CapaStatus.IN_INVESTIGATION && investigatorLogin === accountLogin) ||
      (status === CapaStatus.IN_PLANNING && implementorLogin === accountLogin)
    ) {
      return true;
    }
    return false;
  };

  const onClickEditHandler = () => {
    props.history.push(`/capa/${capaEntity.id}/edit`);
  };

  return (
    <Row>
      <Col md="8">
        <h2>
          CAPA [<b>{capaEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="investigationInstructions">Investigation Instructions</span>
          </dt>
          <dd>{capaEntity.investigationInstructions}</dd>
          <dt>
            <span id="rootCause">Root Cause</span>
          </dt>
          <dd>{capaEntity.rootCause}</dd>
          <dt>
            <span id="remedialAction">Remedial Action</span>
          </dt>
          <dd>{capaEntity.remedialAction}</dd>
          <dt>
            <span id="correctiveAction">Corrective Action</span>
          </dt>
          <dd>{capaEntity.correctiveAction}</dd>
          <dt>
            <span id="preventativeAction">Preventative Action</span>
          </dt>
          <dd>{capaEntity.preventativeAction}</dd>
          <dt>
            <span id="implementationPlan">Implementation Plan</span>
          </dt>
          <dd>{capaEntity.implementationPlan}</dd>
          <dt>
            <span id="investigationDue">Investigation Due</span>
          </dt>
          <dd>
            {capaEntity.investigationDue ? (
              <TextFormat value={capaEntity.investigationDue} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="implementationDue">Implementation Due</span>
          </dt>
          <dd>
            {capaEntity.implementationDue ? (
              <TextFormat value={capaEntity.implementationDue} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">Created By</span>
          </dt>
          <dd>{capaEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">Created Date</span>
          </dt>
          <dd>{capaEntity.createdDate ? <TextFormat value={capaEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedBy">Updated By</span>
          </dt>
          <dd>{capaEntity.updatedBy}</dd>
          <dt>
            <span id="updatedDate">Updated Date</span>
          </dt>
          <dd>{capaEntity.updatedDate ? <TextFormat value={capaEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{capaEntity.status}</dd>
          <dt>Followup</dt>
          <dd>{capaEntity.followup ? capaEntity.followup.id : ''}</dd>
          <dt>Investigator</dt>
          <dd>{capaEntity.investigator?.email || ''}</dd>
          <dt>Implementor</dt>
          <dd>{capaEntity.implementor?.email || ''}</dd>
          {capaEntity.incident == null ? (
            ''
          ) : (
            <span>
              <dt>
                <span id="incidentId">Incident</span>
              </dt>
              <dd>
                <a href={'/incident/' + capaEntity.incident.id}>
                  {capaEntity.incident.incidentId} - {capaEntity.incident.title}
                </a>
              </dd>
            </span>
          )}
          {capaEntity.followupId == null ? (
            ''
          ) : (
            <span>
              <dt>
                <span id="followupId">Followup</span>
              </dt>
              <dd>
                <a href={'/followup/' + capaEntity.followupId}>{capaEntity.followupId}</a>
              </dd>
            </span>
          )}
        </dl>
        <BackButton replace={true} color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </BackButton>
        &nbsp;
        <Button
          onClick={onClickEditHandler}
          replace
          disabled={!isAllowedToEdit()}
          color={!isAllowedToEdit() ? 'outline-secondary' : 'primary'}
        >
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, capa, managementReviewBoard }: IRootState) => ({
  accountLogin: authentication.account.login,
  capaEntity: capa.entity,
  mrbList: managementReviewBoard.entities,
  mrbEntity: managementReviewBoard.entity,
});

const mapDispatchToProps = { getEntity, getMrbList, getMrbEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CapaDetail);
