import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { IManagementReviewBoard } from '../model/management-review-board.model';
import { IMrbItem } from '../model/mrbItem.model';
import { MrbItemType } from '../model/enumerations/mrb-item-type.model';
import { MrbItemStatus } from '../model/enumerations/mrb-item-status.model';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
  isMrbMemberOrMrbItemAssigneeRequired?: boolean;
}

export interface IPrivateRouteProps extends IOwnProps, StateProps {}

export const PrivateRouteComponent = ({
  component: Component,
  isAuthenticated,
  sessionHasBeenFetched,
  isAuthorized,
  hasAnyAuthorities = [],
  isMrbMemberOrMrbItemAssigneeRequired,
  ...rest
}: IPrivateRouteProps) => {
  const checkAuthorities = props =>
    isAuthorized ? (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    ) : (
      <div className="insufficient-authority">
        <div className="alert alert-danger">You are not authorized to access this page.</div>
      </div>
    );

  const renderRedirect = props => {
    if (!sessionHasBeenFetched) {
      return <div></div>;
    } else {
      return isAuthenticated ? (
        checkAuthorities(props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  if (!Component) throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);

  return <Route {...rest} render={renderRedirect} />;
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

export const isMrbMemberOrMrbItemAssignee = (
  mrbEntity: IManagementReviewBoard,
  mrbItemList: IMrbItem[],
  account: any,
  isMrbMemberOrMrbItemAssigneeRequired: boolean
) => {
  if (isMrbMemberOrMrbItemAssigneeRequired) {
    const mrbUsers = mrbEntity?.users || [];
    if (mrbUsers.some(user => user.login === account.login)) {
      return true;
    }

    let mrbItemUserLogins = [];
    mrbItemList.forEach(mrbItem => {
      if (mrbItem.itemType === MrbItemType.CAPA) {
        if (mrbItem.status === MrbItemStatus.CAPA_IN_INVESTIGATION && mrbItem.investigator) {
          mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.investigator.login];
        }
        if (mrbItem.status === MrbItemStatus.CAPA_IN_PLANNING && mrbItem.implementor) {
          mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.implementor.login];
        }
      }
      if (mrbItem.itemType === MrbItemType.FOLLOWUP) {
        if (mrbItem.assignee) {
          mrbItemUserLogins = [...mrbItemUserLogins, mrbItem.assignee.login];
        }
      }
    });

    mrbItemUserLogins = [...new Set(mrbItemUserLogins)];
    if (mrbItemUserLogins.some(login => login === account.login)) {
      return true;
    }
    return false;
  }
  return true;
};

const mapStateToProps = (
  {
    authentication: { isAuthenticated, account, sessionHasBeenFetched },
    managementReviewBoard: { entity },
    mrbItem: { entities },
  }: IRootState,
  { hasAnyAuthorities = [], isMrbMemberOrMrbItemAssigneeRequired = false }: IOwnProps
) => ({
  isAuthenticated,
  isAuthorized:
    hasAnyAuthority(account.authorities, hasAnyAuthorities) &&
    isMrbMemberOrMrbItemAssignee(entity, [...entities], account, isMrbMemberOrMrbItemAssigneeRequired),
  sessionHasBeenFetched,
});

type StateProps = ReturnType<typeof mapStateToProps>;

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export const PrivateRouteMrbItems = connect(mapStateToProps, null, null, { pure: false })(PrivateRouteComponent);

export default PrivateRouteMrbItems;
