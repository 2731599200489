import React, { useState } from 'react';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Table, Input } from 'reactstrap';
import { MrbItemType } from 'app/shared/model/enumerations/mrb-item-type.model';
import { IMrbItem } from 'app/shared/model/mrbItem.model';
import { VoteOutcome } from 'app/shared/model/enumerations/vote-outcome.model';
import { FieldMessage } from 'app/common/components/ui/button/FieldMessage';

const DEFAULT_OUTCOME = VoteOutcome.INCIDENT_INITIATE_CAPA;
const REQUEST_NOTE_MSG = 'Enter a note for the vote';

interface IMrbItemIncident extends IMrbItem {
  itemType: MrbItemType.INCIDENT;
}

export interface IVotingModalProps {
  incident: IMrbItemIncident;
  showModal: boolean;
  toggleModal: Function;
  voteHandler: Function;
  rowClassName: string;
}

export const IncidentVotingModal = (props: IVotingModalProps) => {
  const { incident, showModal, toggleModal, voteHandler, rowClassName } = props;

  const [note, setNote] = useState('');
  const [requestNote, setRequestNote] = useState(false);
  const [outcome, setOutcome] = useState(DEFAULT_OUTCOME);

  const resetModal = () => {
    setNote('');
    setOutcome(DEFAULT_OUTCOME);
  };

  const noteChangeHandler = event => {
    setNote(event.target.value);
    if (note) {
      setRequestNote(false);
    }
  };

  const formSubmitHandler = event => {
    event.preventDefault();
    if (note && outcome) {
      const vote = {
        note,
        outcome,
        incident: {
          id: incident.id,
        },
        createdDate: null,
        updatedDate: null,
      };
      voteHandler(vote);
      toggleModal();
      resetModal();
    }
    if (!note) {
      setRequestNote(true);
    }
  };

  const cancelHandler = () => {
    toggleModal();
    resetModal();
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} className={'className'}>
      <Form onSubmit={formSubmitHandler}>
        <ModalHeader toggle={toggleModal}>Incident Vote</ModalHeader>
        <ModalBody>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">ID</span>
            </dt>
            <dd>{incident.id || null}</dd>
            <dt>
              <span id="title">Incident Title</span>
            </dt>
            <dd className="text-truncate">{incident.incidentTitle || null}</dd>
          </dl>
          <FormGroup>
            <Label id="noteLabel" for="vote-note">
              Note
            </Label>
            <Input id="vote-note" type="text" name="note" value={note} placeholder="Enter a note" onChange={noteChangeHandler} />
            <FieldMessage condition={requestNote} message={REQUEST_NOTE_MSG} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" onClick={() => setOutcome(VoteOutcome.INCIDENT_INITIATE_CAPA)}>
            Initiate Capa
          </Button>
          <Button type="submit" color="danger" onClick={() => setOutcome(VoteOutcome.INCIDENT_CLOSE_NO_CAPA)}>
            Close No Capa
          </Button>
          <Button type="button" className="mr-1" color="secondary" onClick={cancelHandler} tabIndex="1">
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
