import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

export const CheckboxIcon = ({ isChecked }: { isChecked: boolean }) => {
    if (isChecked) {
        return <FontAwesomeIcon className="checked-icon" icon={faCheckSquare} />;
    }
    return <FontAwesomeIcon className="unchecked-icon" icon={faSquare} />;
}
