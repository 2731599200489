import moment from 'moment';

import {
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_Z,
  APP_SERVER_DATE_FORMAT,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateFromServer = (date, dateType?) => {
  const dateTypeVal: string = dateType && dateType !== undefined ? dateType : 'YYYY-MM-DD';
  return date ? moment(date, dateTypeVal).format(APP_SERVER_DATE_FORMAT) : null;
};

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const convertDateToServer = date => (date ? moment(date, APP_LOCAL_DATE_FORMAT).format(APP_SERVER_DATE_FORMAT) : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displayDefaultDate = () => moment().startOf('day').format(APP_LOCAL_DATE_FORMAT);
