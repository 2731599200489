export const enum VoteOutcome {
  INCIDENT_INITIATE_CAPA = 'INCIDENT_INITIATE_CAPA',

  INCIDENT_CLOSE_NO_CAPA = 'INCIDENT_CLOSE_NO_CAPA',

  CAPA_INVESTIGATION_APPROVED = 'CAPA_INVESTIGATION_APPROVED',

  CAPA_INVESTIGATION_CHANGE_REQUESTED = 'CAPA_INVESTIGATION_CHANGE_REQUESTED',

  CAPA_IMPLEMENTATION_PLAN_APPROVED = 'CAPA_IMPLEMENTATION_PLAN_APPROVED',

  CAPA_IMPLEMENTATION_PLAN_CHANGE_REQUESTED = 'CAPA_IMPLEMENTATION_PLAN_CHANGE_REQUESTED',

  FOLLOWUP_EFFECTIVE_CLOSE = 'FOLLOWUP_EFFECTIVE_CLOSE',

  FOLLOWUP_INEFFECTIVE_NEW_INCIDENT = 'FOLLOWUP_INEFFECTIVE_NEW_INCIDENT',
}
