/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Table, Button, Input, Form, FormGroup } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { getEntities as getAllRecordList } from 'app/modules/all-record/all-record.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { AllRecordType } from 'app/shared/model/enumerations/all-record-type.model';
import { AllRecordStatus } from 'app/shared/model/enumerations/all-record-status.model';
import { IAllRecord } from 'app/shared/model/allRecord.model';

const INCIDENT_ROW_CLASS = 'table-info';
const CAPA_ROW_CLASS = 'table-warning';
const FOLLOWUP_ROW_CLASS = 'table-success';

const textTruncateStyle = {
  maxWidth: '360px',
};

const groupItemsByKey = (list: any[], key: string) => {
  return list.reduce(function (previousItem: any, currentItem: any) {
    (previousItem[currentItem[key]] = previousItem[currentItem[key]] || []).push(currentItem);
    return previousItem;
  }, {});
};

export interface IAllRecordProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const AllRecord = (props: IAllRecordProps) => {
  const { accountLogin, mrbEntity, allRecordList, allRecordLoading, match } = props;
  const [isMrbMember, setIsMrbMember] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  let allRecordSortedList = [];

  const dueDateHandler = (allRecord: IAllRecord): string => {
    if (allRecord.recordType === AllRecordType.CAPA) {
      if (allRecord.status === AllRecordStatus.CAPA_IN_INVESTIGATION) {
        return allRecord.investigationDue;
      }
      if (allRecord.status === AllRecordStatus.CAPA_IN_PLANNING) {
        return allRecord.implementationDue;
      }
    }
    if (allRecord.recordType === AllRecordType.FOLLOWUP) {
      return allRecord.scheduledDate;
    }
    return null;
  };

  function sortIncidents(incidentList) {
    return incidentList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  function sortCapas(capaList) {
    return capaList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;

      if (!firstDate && secondDate) {
        return -1;
      }
      if (firstDate && !secondDate) {
        return 1;
      }

      if (!firstDate && !secondDate) {
        if (firstItem.status === AllRecordStatus.CAPA_IN_INVESTIGATION && secondItem.status === AllRecordStatus.CAPA_IN_INVESTIGATION) {
          if (!firstItem.investigator) {
            return -1;
          }
          if (!secondItem.investigator) {
            return 1;
          }
          return 0;
        }

        if (firstItem.status === AllRecordStatus.CAPA_IN_PLANNING && secondItem.status === AllRecordStatus.CAPA_IN_PLANNING) {
          if (!firstItem.implementor) {
            return -1;
          }
          if (!secondItem.implementor) {
            return 1;
          }
          return 0;
        }

        if (firstItem.status === AllRecordStatus.CAPA_IN_INVESTIGATION) {
          return -1;
        }
        if (secondItem.status === AllRecordStatus.CAPA_IN_INVESTIGATION) {
          return 1;
        }
        return 0;
      }

      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  function sortFollowups(followupList) {
    return followupList.sort((firstItem, secondItem) => {
      const firstDate = dueDateHandler(firstItem) ? new Date(dueDateHandler(firstItem)) : null;
      const secondDate = dueDateHandler(secondItem) ? new Date(dueDateHandler(secondItem)) : null;
      if (!firstDate) {
        return -1;
      }
      if (!secondDate) {
        return 1;
      }
      return firstDate < secondDate ? -1 : firstDate > secondDate ? 1 : 0;
    });
  }

  function groupAndSortAllRecords() {
    const groupedAllRecordList = groupItemsByKey([...allRecordList], 'recordType');
    const incidentSortedList = sortIncidents(groupedAllRecordList[AllRecordType.INCIDENT] || []);
    const capaSortedList = sortCapas(groupedAllRecordList[AllRecordType.CAPA] || []);
    const followupSortedList = sortFollowups(groupedAllRecordList[AllRecordType.FOLLOWUP] || []);
    allRecordSortedList = [...incidentSortedList, ...capaSortedList, ...followupSortedList];
  }

  useEffect(() => {
    props.getAllRecordList();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    props.getAllRecordList();
    props.getMrbList();
  }, []);

  useEffect(() => {
    if (mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [mrbEntity, accountLogin]);

  const onClickViewHandler = (entityPath: string, id) => {
    props.history.push(`${entityPath}/${id}`);
  };

  const onClickEditHandler = (entityPath: string, id) => {
    props.history.push(`${entityPath}/${id}/edit`);
  };

  const entityPathHandler = (allRecordType: AllRecordType): string => {
    if (allRecordType === AllRecordType.INCIDENT) {
      return '/incident';
    }
    if (allRecordType === AllRecordType.CAPA) {
      return '/capa';
    }
    if (allRecordType === AllRecordType.FOLLOWUP) {
      return '/followup';
    }
    return null;
  };

  const rowColorHandler = (typeType: string): string => {
    if (typeType === AllRecordType.INCIDENT) {
      return INCIDENT_ROW_CLASS;
    }
    if (typeType === AllRecordType.CAPA) {
      return CAPA_ROW_CLASS;
    }
    if (typeType === AllRecordType.FOLLOWUP) {
      return FOLLOWUP_ROW_CLASS;
    }
    return null;
  };

  const assigneeHandler = (allRecord: IAllRecord): string => {
    if (allRecord.recordType === AllRecordType.CAPA) {
      if (allRecord.status === AllRecordStatus.CAPA_IN_INVESTIGATION) {
        return allRecord.investigator?.email || null;
      }
      if (allRecord.status === AllRecordStatus.CAPA_IN_PLANNING) {
        return allRecord.implementor?.email || null;
      }
    }
    if (allRecord.recordType === AllRecordType.FOLLOWUP) {
      return allRecord.assignee?.email || null;
    }
    return null;
  };

  const isAllowedToEdit = (allRecord: IAllRecord): boolean => {
    if (isMrbMember) {
      return true;
    }
    if (allRecord.recordType === AllRecordType.CAPA) {
      if (allRecord.status === AllRecordStatus.CAPA_IN_INVESTIGATION && allRecord.investigator) {
        return accountLogin === allRecord.investigator.login;
      }
      if (allRecord.status === AllRecordStatus.CAPA_IN_PLANNING && allRecord.implementor) {
        return accountLogin === allRecord.implementor.login;
      }
    }
    if (allRecord.recordType === AllRecordType.FOLLOWUP) {
      if (allRecord.assignee) {
        return accountLogin === allRecord.assignee.login;
      }
    }
    return false;
  };

  const searchFields = [
    'id',
    'recordType',
    'incidentTitle',
    'status',
    'investigator',
    'implementor',
    'assignee',
    'incidentCreatedDate',
    'investigationDue',
    'implementationDue',
    'scheduledDate',
  ];

  function formatFieldValue<T>(fieldName: string, object: T) {
    let data = object[fieldName];

    if (data?.email) {
      data = data.email;
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(data)) {
      const date = data.split('-');
      data = `${date[1]}/${date[2]}/${date[0]}`;
    }
    return data;
  }

  function search<T>(object: T): boolean {
    const data = searchFields.map(fieldName => formatFieldValue(fieldName, object));
    return data.some(value => value?.toString().toLowerCase().includes(searchFor.toLowerCase()));
  }

  const tableBodyBuilder = () => {
    groupAndSortAllRecords();
    return (
      <tbody>
        {allRecordSortedList
          .filter(allRecord => search(allRecord))
          .map((allRecord, i) => (
            <tr key={`entity-${i}`} className={rowColorHandler(allRecord.recordType)}>
              <td>{allRecord.id}</td>
              <td>{allRecord.recordType}</td>
              <td className="text-truncate" style={textTruncateStyle}>
                {allRecord.incidentTitle}
              </td>
              <td>{allRecord.status}</td>
              <td>{assigneeHandler(allRecord)}</td>
              <td>
                {dueDateHandler(allRecord) ? (
                  <TextFormat type="date" value={dueDateHandler(allRecord)} format={APP_LOCAL_DATE_FORMAT} />
                ) : null}
              </td>
              <td>
                <div className="col-md-12 text-right">
                  <Button
                    onClick={() => onClickViewHandler(entityPathHandler(allRecord.recordType), allRecord.id)}
                    color="info"
                    size="sm"
                    className="mr-2"
                  >
                    <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                  </Button>
                  <Button
                    onClick={() => onClickEditHandler(entityPathHandler(allRecord.recordType), allRecord.id)}
                    disabled={!isAllowedToEdit(allRecord)}
                    color={!isAllowedToEdit(allRecord) ? 'outline-secondary' : 'primary'}
                    size="sm"
                    className="mr-2"
                  >
                    <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

  const onChange = event => {
    setSearchFor(event.target.value);
  };

  return (
    <div>
      <h2 id="all-record-heading">
        All Records
        <Link to={'incident/new'} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Incident
        </Link>
      </h2>
      <br />
      <Form>
        <FormGroup>
          <Input type="search" placeholder="Filter" onChange={onChange} />
        </FormGroup>
      </Form>
      <div className="table-responsive">
        {allRecordList && allRecordList.length > 0 ? (
          <Table className="table-hover" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Incident Title</th>
                <th>Status</th>
                <th>Assignee</th>
                <th>Due Date</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            {tableBodyBuilder()}
          </Table>
        ) : (
          !allRecordLoading && <div className="alert alert-warning">No Records found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, managementReviewBoard, allRecord }: IRootState) => ({
  accountLogin: authentication.account.login,
  mrbEntity: managementReviewBoard.entity,
  allRecordList: allRecord.entities,
  allRecordLoading: allRecord.loading,
});

const mapDispatchToProps = {
  getMrbList,
  getMrbEntity,
  getAllRecordList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AllRecord);
