import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { IMrbItem } from 'app/shared/model/mrbItem.model';

export const ACTION_TYPES = {
  FETCH_MRB_ITEM_LIST: 'mrbItem/FETCH_MRB_ITEM_LIST',
  RESET: 'mrbItem/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IMrbItem>,
};

export type MrbItemState = Readonly<typeof initialState>;

// Reducer

export default (state: MrbItemState = initialState, action): MrbItemState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_MRB_ITEM_LIST): {
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    }
    case FAILURE(ACTION_TYPES.FETCH_MRB_ITEM_LIST): {
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_MRB_ITEM_LIST): {
      return {
        ...state,
        entities: action.payload.data,
        loading: false,
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
};

const apiUrl = 'api/mrb-items';

// Actions

export const getEntities: ICrudGetAllAction<IMrbItem> = () => {
  return {
    type: ACTION_TYPES.FETCH_MRB_ITEM_LIST,
    payload: axios.get<IMrbItem>(`${apiUrl}`),
  };
};

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET,
  };
};
