import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import incident, {
  IncidentState
} from 'app/entities/incident/incident.reducer';
// prettier-ignore
import capa, {
  CapaState
} from 'app/entities/capa/capa.reducer';
// prettier-ignore
import followup, {
  FollowupState
} from 'app/entities/followup/followup.reducer';
// prettier-ignore
import vote, {
  VoteState
} from 'app/modules/administration/vote/vote.reducer';
// prettier-ignore
import managementReviewBoard, {
  ManagementReviewBoardState
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
// prettier-ignore
import mrbItem, {
  MrbItemState
} from 'app/modules/mrb-item/mrb-item.reducer';
import allRecord, { AllRecordState } from 'app/modules/all-record/all-record.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly incident: IncidentState;
  readonly capa: CapaState;
  readonly followup: FollowupState;
  readonly vote: VoteState;
  readonly managementReviewBoard: ManagementReviewBoardState;
  readonly mrbItem: MrbItemState;
  readonly allRecord: AllRecordState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  incident,
  capa,
  followup,
  vote,
  managementReviewBoard,
  mrbItem,
  allRecord,

  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
