import React from 'react'

type FieldMessageProps = {
    condition: boolean;
    message: string;
}

export const FieldMessage = (props: FieldMessageProps) => {
    if (props.condition) {
        return (
            <p className='field-message'>{props.message}</p>
        );
    }
    return null;
}
