import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {getSortState, TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getEntities, reset} from './followup.reducer';
import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {ITEMS_PER_PAGE} from 'app/shared/util/pagination.constants';
import {overridePaginationStateWithQueryParams} from 'app/shared/util/entity-utils';

export interface IFollowupProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Followup = (props: IFollowupProps) => {
  const { followupList, match, loading } = props;

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [sorting, setSorting] = useState(false);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const resetAll = () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
    setSorting(true);
  };

  const onClickViewHandler = (id) => {
    props.history.push(`${match.url}/${id}`);
  }

  const onClickEditHandler = (id) => {
    props.history.push(`${match.url}/${id}/edit`);
  }

  const onClickLinkTitleHandler = (id) => {
    props.history.push(`/incident/${id}`);
  }

  const onClickLinkIdHandler = (id) => {
    props.history.push(`${match.url}/${id}`);
  }

  return (
    <div>
      <h2 id="followup-heading">
        Followups
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          pageStart={paginationState.activePage}
          loadMore={handleLoadMore}
          hasMore={paginationState.activePage - 1 < props.links.next}
          loader={<div className="loader">Loading ...</div>}
          threshold={0}
          initialLoad={false}
        >
          {followupList && followupList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    ID <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Incident ID
                  </th>
                  <th>
                    Note
                  </th>
                  <th className="hand" onClick={sort('scheduledDate')}>
                    Due Date <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Assignee <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('status')}>
                    Status <FontAwesomeIcon icon="sort" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {followupList.map((followup, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button onClick={() => onClickLinkIdHandler(followup.id)} color="link" size="sm">
                        {followup.id}
                      </Button>
                    </td>
                    <td>
                      <Button onClick={() => onClickLinkTitleHandler(followup.capa.incident.id)} to={`/incident/${followup.capa.incident.id}`} color="link" size="sm">
                        {followup.capa.incident.incidentId}
                      </Button>
                    </td>
                    <td>{followup.note}</td>
                    <td>
                      {followup.scheduledDate ? <TextFormat type="date" value={followup.scheduledDate} format={APP_LOCAL_DATE_FORMAT} /> : null}
                    </td>
                    <td>{followup.assignee ? followup.assignee.email : ''}</td>
                    <td>{followup.status}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button onClick={() => onClickViewHandler(followup.id)} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button onClick={() => onClickEditHandler(followup.id)} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Followups found</div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ followup }: IRootState) => ({
  followupList: followup.entities,
  loading: followup.loading,
  totalItems: followup.totalItems,
  links: followup.links,
  entity: followup.entity,
  updateSuccess: followup.updateSuccess,
});

const mapDispatchToProps = {
  getEntities,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Followup);
