import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './followup.reducer';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import BackButton from 'app/common/components/ui/button/BackButton';

export interface IFollowupDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const FollowupDetail = (props: IFollowupDetailProps) => {
  const { accountLogin, followupEntity, mrbList, mrbEntity } = props;

  const [isMrbMember, setIsMrbMember] = useState(false);

  useEffect(() => {
    props.getEntity(props.match?.params?.id);
    props.getMrbList();
  }, []);

  useEffect(() => {
    if (mrbList.length === 1) {
      props.getMrbEntity(mrbList[0]?.id);
    }
  }, [mrbList]);

  useEffect(() => {
    if (mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [mrbEntity, accountLogin]);

  const isAllowedToEdit = (): boolean => {
    if (isMrbMember || followupEntity.assignee?.login === accountLogin) {
      return true;
    }
    return false;
  };

  const onClickEditHandler = () => {
    props.history.push(`/followup/${followupEntity.id}/edit`);
  };

  return (
    <Row>
      <Col md="8">
        <h2>
          Followup [<b>{followupEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="note">Notes</span>
          </dt>
          <dd>{followupEntity.note}</dd>
          <dt>
            <span id="scheduledDate">Due Date</span>
          </dt>
          <dd>
            {followupEntity.scheduledDate ? (
              <TextFormat value={followupEntity.scheduledDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdBy">Created By</span>
          </dt>
          <dd>{followupEntity.createdBy}</dd>
          <dt>
            <span id="createdDate">Created Date</span>
          </dt>
          <dd>
            {followupEntity.createdDate ? <TextFormat value={followupEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{followupEntity.status}</dd>
          <dt>Assignee</dt>
          <dd>{followupEntity.assignee?.email || ''}</dd>
          <dt>CAPA</dt>
          <dd>{followupEntity.capa?.id ? <a href={'/capa/' + followupEntity.capa.id}>{followupEntity.capa.id}</a> : ''}</dd>
          <dt>Incident</dt>
          <dd>
            {followupEntity.capa?.incident?.id && followupEntity.capa?.incident?.incidentId ? (
              <a href={'/incident/' + followupEntity.capa.incident.id}>{followupEntity.capa.incident.incidentId}</a>
            ) : (
              ''
            )}
          </dd>
        </dl>
        <BackButton replace={true} color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </BackButton>
        &nbsp;
        <Button
          onClick={onClickEditHandler}
          replace
          disabled={!isAllowedToEdit()}
          color={!isAllowedToEdit() ? 'outline-secondary' : 'primary'}
        >
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, followup, managementReviewBoard }: IRootState) => ({
  accountLogin: authentication.account.login,
  followupEntity: followup.entity,
  mrbList: managementReviewBoard.entities,
  mrbEntity: managementReviewBoard.entity,
});

const mapDispatchToProps = { getEntity, getMrbList, getMrbEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FollowupDetail);
