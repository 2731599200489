export const enum AllRecordStatus {
  INCIDENT_NEW = 'NEW',
  INCIDENT_CAPA = 'INITIATE_CAPA',
  INCIDENT_CLOSED = 'CLOSED',
  CAPA_IN_INVESTIGATION = 'IN_INVESTIGATION',
  CAPA_IN_PLANNING = 'IN_PLANNING',
  CAPA_END = 'FOLLOWUP',
  FOLLOWUP_SCHEDULED = 'SCHEDULED',
  FOLLOWUP_CAPA_EFFECTIVE = 'CAPA_EFFECTIVE',
  FOLLOWUP_REJECTED = 'REJECTED',
}
