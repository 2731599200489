import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './incident.reducer';
import {
  getEntities as getMrbList,
  getEntity as getMrbEntity,
} from 'app/modules/administration/management-review-board/management-review-board.reducer';
import BackButton from 'app/common/components/ui/button/BackButton';

export interface IIncidentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const IncidentDetail = (props: IIncidentDetailProps) => {
  const { accountLogin, incidentEntity, mrbList, mrbEntity } = props;

  const [isMrbMember, setIsMrbMember] = useState(false);

  useEffect(() => {
    props.getEntity(props.match.params.id);
    props.getMrbList();
  }, []);

  useEffect(() => {
    if (mrbList.length === 1) {
      props.getMrbEntity(mrbList[0]?.id);
    }
  }, [mrbList]);

  useEffect(() => {
    if (mrbEntity.users?.length > 0) {
      const mrbUsers = mrbEntity.users || [];
      setIsMrbMember(mrbUsers.some(user => user.login === accountLogin));
    }
  }, [mrbEntity, accountLogin]);

  const onClickEditHandler = () => {
    props.history.push(`/incident/${incidentEntity.id}/edit`);
  };

  return (
    <Row>
      <Col md="8">
        <h2>
          Incident [
          <b>
            {incidentEntity.incidentId} - {incidentEntity.title}
          </b>
          ]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">Title</span>
          </dt>
          <dd>{incidentEntity.title}</dd>
          <dt>
            <span id="description">Description</span>
          </dt>
          <dd>{incidentEntity.description}</dd>
          <dt>
            <span id="actionTaken">Action Taken</span>
          </dt>
          <dd>{incidentEntity.actionTaken}</dd>
          <dt>
            <span id="createdBy">Created By</span>
          </dt>
          <dd>{incidentEntity.createdBy}</dd>
          <dt>
            <span id="status">Status</span>
          </dt>
          <dd>{incidentEntity.status}</dd>
          {incidentEntity.capaId == null ? (
            ''
          ) : (
            <span>
              <dt>
                <span id="capaId">CAPA</span>
              </dt>
              <dd>
                <a href={'/capa/' + incidentEntity.capaId}>{incidentEntity.capaId}</a>
              </dd>
            </span>
          )}
          {incidentEntity.followupId == null ? (
            ''
          ) : (
            <span>
              <dt>
                <span id="followupId">Followup</span>
              </dt>
              <dd>
                <a href={'/followup/' + incidentEntity.followupId}>{incidentEntity.followupId}</a>
              </dd>
            </span>
          )}
        </dl>
        <BackButton replace={true} color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </BackButton>
        &nbsp;
        <Button onClick={onClickEditHandler} replace disabled={!isMrbMember} color={!isMrbMember ? 'outline-secondary' : 'primary'}>
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ authentication, incident, managementReviewBoard }: IRootState) => ({
  accountLogin: authentication.account.login,
  incidentEntity: incident.entity,
  mrbList: managementReviewBoard.entities,
  mrbEntity: managementReviewBoard.entity,
});

const mapDispatchToProps = { getEntity, getMrbList, getMrbEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetail);
