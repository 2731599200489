import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';

export interface IManagementReviewBoard {
  id?: number;
  name?: string;
  note?: string;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  users?: IUser[];
}

export const defaultValue: Readonly<IManagementReviewBoard> = {};
