import { Moment } from 'moment';
import { IManagementReviewBoard } from 'app/shared/model/management-review-board.model';
import { IUser } from 'app/shared/model/user.model';
import { ICapa } from 'app/shared/model/capa.model';
import { FollowupStatus } from 'app/shared/model/enumerations/followup-status.model';

export interface IFollowup {
  id?: number;
  note?: string;
  scheduledDate?: string;
  createdBy?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  status?: FollowupStatus;
  mrb?: IManagementReviewBoard;
  assignee?: IUser;
  capa?: ICapa;
}

export const defaultValue: Readonly<IFollowup> = {};
