import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import {Button} from 'reactstrap';

interface IBackButton{
  id?: string;
  color?: string;
  replace?: boolean;
}

const BackButton = (props: PropsWithChildren<IBackButton>) => {
  const {id, color, replace} = props;

  const history = useHistory();

  const onClickBackHandler = () => {
    history.goBack();
  }

  return (
      <Button id={id} onClick={onClickBackHandler} color={color} replace={replace}>
        {props.children}
      </Button>
  )
}

BackButton.defaultProps = {
  replace: false
};

export default BackButton;